/* General styles */
body, h1, h2, h3, p, ul {
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: white;
}

body {
    background: linear-gradient(135deg, #0d1117, #161b22);
    color: #c9d1d9;
    overflow-x: hidden;
}

img {
    width: 48px;
    height: 48px;
}

/* Header and navigation styles */
header {
    display: flex;
    padding: 20px;
    background: rgba(13, 17, 23, 0.8);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.spacer {
    width: 12px;
    display: inline-block;
}

header .logo {
    font-size: 24px;
    color: #58a6ff;
    font-weight: bold;
    margin-top: 10px;
}

header a {
    color: #000;
}

header ul {
    list-style: none;
    display: flex;
}

header ul li {
    margin-left: 30px;
    margin-top: 15px;
}

header ul li a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
}

header ul li a:hover {
    color: #58a6ff;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: #bbb;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #fff;
  text-shadow: 0 0 10px #007bff; /* Glowing effect on hover */
}

.mobile-logo-menu-button {
    display: none;
}

/* Mobile menu styles */
.mobile-menu-button {
    display: inline-block;
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

/* Rotate the button to form an X when active */
.mobile-menu-button.active {
    transform: rotate(90deg);
    font-size: 24px;
    content: '✖';
}

.mobile-menu {
    display: block;
    position: absolute;
    width: 130px;
    height: 218px;
    background: #0d1117;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-menu ul li {
    margin: 0;
    padding: 15px;
}

.mobile-menu ul li a {
    text-decoration: none;
    color: white;
    display: block;
    transition: color 0.3s;
}

.mobile-menu ul li a:hover {
    color: #58a6ff;
}

/* Show mobile menu button and hide desktop menu on small screens */
@media (max-width: 768px) {
    .desktop-menu {
        display: none;
    }

    .mobile-logo-menu-button {
        display: block;
        margin-top: 8px;
    }

    .mobile-menu-button {
        display: block;
    }
}

/* Show mobile menu when the button is clicked */
.mobile-menu.active {
    display: block;
}

/* Search bar section */
.hero {
  background-color: #1e1e1e; /* Dark background */
  color: white;
  padding: 60px 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.hero .hero-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #007bff; /* Glowing blue text */
}

.search-bar {
  width: 80%;
  max-width: 600px;
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  outline: none;
  background-color: #333;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
}

.search-bar::placeholder {
  color: #aaa;
}

.search-bar:focus {
  border: 2px solid #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6); /* Blue glow */
}

.search-bar:focus::placeholder {
  color: #fff;
}

/* View button styles */
.feature-item button {
    display: block;
    margin: 15px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff; /* Glowing blue */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.feature-item button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.6); /* Glowing effect */
}

.feature-item button:active {
    background-color: #004080; /* Even darker blue on click */
}

/* Search results container */
.search-results {
    margin-top: 10px;
    width: 80%; /* Matches the width of the search bar */
    max-width: 600px; /* Same as search bar max width */
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #1e1e1e; /* Dark container background */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

/* Styling the search results title */
.search-results h2 {
    font-size: 20px;
    color: #007bff; /* Glowing blue text */
    margin-bottom: 15px;
    text-align: left; /* Align title with search results */
}

/* Search results list */
.search-results ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Individual search result item */
.search-results li {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #2b2b2b; /* Dark item background */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.search-results li:hover {
    background-color: #333;
    transform: translateY(-3px); /* Slight lift effect */
}

/* Links for search result items */
.search-results a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    transition: color 0.3s ease;
}

.search-results a:hover {
    color: #58a6ff; /* Light blue on hover */
}

/* No results message */
.no-results {
    text-align: center;
    color: #aaa;
    font-size: 16px;
    margin-top: 10px;
}

/* Categories section */
.features {
  padding: 40px 20px;
  text-align: center;
  background-color: #1e1e1e; /* Dark background */
}

.features h2 {
  font-size: 28px;
  margin-bottom: 40px;
  color: #007bff; /* Glowing blue text */
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.feature-item {
  background-color: #2b2b2b; /* Dark feature items */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.feature-item h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #007bff; /* Glowing blue text */
}

.feature-item p {
  color: #bbb; /* Light gray text */
  font-size: 16px;
}

/* Footer styles */
footer {
  background-color: #121212; /* Dark footer background */
  color: white;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.5);
}

footer .footer-content {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
}

footer .footer-logo {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

footer .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

footer .footer-links li {
  display: inline;
  margin: 0 10px;
}

footer .footer-links li a {
  color: #bbb;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

footer .footer-links li a:hover {
  color: #fff;
  text-shadow: 0 0 10px #007bff; /* Glowing effect on hover */
}

footer p {
  font-size: 14px;
  color: #aaa;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-links li:nth-last-child(2),
  .footer-links li:last-child {
    flex-basis: 100%; /* Force these two items to take the full width */
    text-align: center;
    margin-top: 10px;
  }
}

.vertical-spacer {
    height: 25px;
}
